@media screen and (max-width: 575px) {
  .innerpageHeader .innerHeader .innerHeaderWrapper .HeaderLHS {
    flex-direction: column;
    gap: 15px;
  }
  .innerpageHeader .innerHeader .innerHeaderWrapper .HeaderRHS {
    margin: 15px 0px 8px;
  }
}
.CartPageContentWrapper {
  display: flex;
  margin: 0px 0px 50px;
}

.CartPageContenLHS {
  width: calc(100% - (30% + 30px));
  min-width: calc(100% - (30% + 30px));
  margin-right: 30px;
}

.CartPageContenRHS {
  width: 30%;
}

.CartAlertDiv {
  background-color: #FFF4F1;
  border-radius: 7px;
  padding: 15px 15px;
  display: flex;
  -moz-column-gap: 15px;
       column-gap: 15px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 0px 0px 10px;
}

.CartAlertDiv .CloseIcon {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  cursor: pointer;
}

@media screen and (max-width: 991.33px) {
  .CartPageContentWrapper {
    flex-direction: column;
  }
  .CartPageContenLHS {
    width: 100%;
    min-width: 100%;
    margin: 0px 0px 15px;
  }
  .CartPageContenRHS {
    width: 100%;
  }
  .CartPageContentWrapper {
    margin: 0px 0px 30px;
  }
}
.CartBoxWrapper {
  border: 1px solid #E7E7E7;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 0px 15px;
  position: relative;
}

.CartBoxWrapper .CartTopBox {
  display: flex;
}

.CartBoxWrapper .CartimgWrapper {
  width: 180px;
  height: 135px;
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}

.CartBoxWrapper .CartimgWrapper img {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.CartBoxWrapper .CartContentWrapper {
  position: relative;
  display: block;
  width: calc(100% - 190px);
  min-width: calc(100% - 190px);
  margin-left: 10px;
}

.CartBoxWrapper .CartContentWrapper h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0px 3px;
  padding: 0px 55px 0px 0px;
}

.CartBoxWrapper .CartContentWrapper .Price {
  color: #F25928;
  font-size: 22px;
  font-weight: 500;
  margin: 0px 0px 12px;
}

.CartBoxWrapper .CartContentWrapper .BtnGroup {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}

.CartBoxWrapper .CartContentWrapper .BtnGroup .cta {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 5px;
  padding: 9px 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 2px;
       column-gap: 2px;
  text-transform: capitalize;
}

.CartBoxWrapper .CartContentWrapper .BtnGroup .cta[aria-expanded=true] {
  background-color: #F25928;
  border-color: #F25928;
  color: #fff;
}

.CartBoxWrapper .CartContentWrapper .BtnGroup .cta.BookingInfoCta[aria-expanded=true]::after {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.CartBoxWrapper .CartContentWrapper .BtnGroup .BookingInfoCta::after {
  content: "";
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center center;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.CartBoxWrapper .CartContentWrapper .EditTrashGroup {
  display: flex;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  min-width: 50px;
  position: absolute;
  right: 0;
  top: 4px;
}

.CartBoxWrapper .CartContentWrapper .EditTrashGroup div {
  background-position: center center;
  background-repeat: no-repeat;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  cursor: pointer;
}

.CartBoxWrapper .CartContentWrapper .EditTrashGroup .Edit {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-pencil-fill' viewBox='0 0 16 16'%3E%3Cpath d='M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z'/%3E%3C/svg%3E");
}

.CartBoxWrapper .CartContentWrapper .EditTrashGroup .Trash {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'/%3E%3C/svg%3E");
}

/*BookingInfoData*/
.CartBottomBox .BookingInfoData {
  position: relative;
  padding: 20px 0px 10px;
}

.CartBottomBox .BookingInfotableData {
  border: 1px solid #ECECEC;
}

.CartBottomBox .BookingInfoData .heading {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0px 0px 10px;
}

.CartBottomBox .BookingInfotableData {
  border: 1px solid #ECECEC;
  display: flex;
}

.CartBottomBox .BookingInfotableData .BookingInfotableDiv {
  width: 50%;
}

.CartBottomBox .BookingInfotableData .BookingInfotableDiv:last-child {
  border-left: 1px solid #ECECEC;
}

.CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  border-bottom: 1px solid #ECECEC;
  padding: 12px 15px;
}

.CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow:last-child {
  border-bottom: 0px;
}

.CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow span {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width:50%;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  overflow-wrap: anywhere;
}
.CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow span:last-child{
  font-weight: 500 !important;
}

.CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow span:first-child {
  width:50%;
  font-weight: 700 !important;
}

@media screen and (max-width: 991.33px) {
  .CartBoxWrapper .CartContentWrapper h4 {
    font-size: 16px;
    padding: 0px 45px 0px 0px;
  }
  .CartBoxWrapper .CartContentWrapper .Price {
    font-size: 15px;
    margin: 0px 0px 5px;
  }
  .CartBoxWrapper .CartimgWrapper {
    width: 110px;
    height: 83px;
  }
  .CartBoxWrapper .CartContentWrapper {
    width: calc(100% - 110px);
    min-width: calc(100% - 110px);
    margin-left: 8px;
  }
  .CartBoxWrapper .CartContentWrapper .EditTrashGroup {
    -moz-column-gap: 8px;
         column-gap: 8px;
    width: 40px;
    min-width: 40px;
  }
  .CartBoxWrapper .CartContentWrapper .EditTrashGroup div {
    background-size: 14px;
  }
  .CartBottomBox .BookingInfoData .heading {
    font-size: 16px;
  }
  .CartBoxWrapper .CartContentWrapper .BtnGroup .cta {
    font-size: 12px;
    padding: 7px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CartBottomBox .BookingInfotableData {
    flex-direction: column;
  }
  .CartBottomBox .BookingInfotableData .BookingInfotableDiv {
    width: 100%;
  }
  .CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow:last-child {
    border-bottom: 1px solid #ECECEC;
  }
  .CartBottomBox .BookingInfotableData .BookingInfotableDiv:last-child {
    border: 0px;
  }
  .CartBottomBox .BookingInfotableData {
    border-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  .CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow span {
    font-size: 12px;
  }
}
.OrderSummaryDiv {
  border: 1px solid #DADADA;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}

.OrderSummaryDiv .OrderSummaryTable {
  padding: 20px 20px 50px;
}

.OrderSummaryDiv .heading {
  font-weight: 600;
  font-size: 20px;
  color: #000;
  text-align: center;
  padding: 15px 10px;
  border-bottom: 1px solid #DADADA;
}

.OrderSummaryTable .OrderSummaryTablebody .OrderSummaryTablerow {
  display: flex;
  -moz-column-gap: 30px;
       column-gap: 30px;
  justify-content: space-between;
  padding: 0px 0px 12px;
}

.OrderSummaryTable .OrderSummaryTablebody .OrderSummaryTablerow span {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.OrderSummaryTable .OrderSummaryTablebody .OrderSummaryTablerow span:last-child, .OrderSummaryTable .OrderSummaryTablebody .OrderSummaryTablerow span b, .OrderSummaryTable .OrderSummaryTablebody .OrderSummaryTablerow span strong
{
  font-weight: 600;
}

.OrderSummaryTable .OrderSummaryTablebody .ProceedCheckoutCta .cta {
  background-color: #F25928;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 12px 15px;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0px 15px;
  transition: all 0.3s;
}

.OrderSummaryTable .OrderSummaryTablebody .ProceedCheckoutCta .cta:hover{
  background-color: #3EABD5;
}

.OrderSummaryTable .OrderSummaryTablebody .PaymentMethodGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
}

.OrderSummaryTable .OrderSummaryTablebody .PaymentMethodGroup a img {
  max-width: 35px;
  margin: 0 auto;
}

@media screen and (max-width: 991.33px) {
  .OrderSummaryDiv .OrderSummaryTable {
    padding: 20px 15px;
  }
  .OrderSummaryDiv .heading {
    font-size: 18px;
    padding: 10px 10px;
  }
  .OrderSummaryTable .OrderSummaryTablebody .ProceedCheckoutCta .cta {
    font-size: 16px;
  }
  .CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow span, .CartBottomBox .BookingInfotableData .BookingInfotableDiv .BookingInfotablerow span:last-child {
    width: 50%;
    white-space: inherit;
  }
}
.BreadcrumbSection .breadcrumb {
  margin: 0px;
  padding: 15px 0px;
}
.BreadcrumbSection .breadcrumb li {
  color: #f25928;
  font-size: 13px;
  font-weight: 500;
}
.BreadcrumbSection .breadcrumb li a {
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.3s;
}
.BreadcrumbSection .breadcrumb li a:hover {
  text-decoration: underline;
  color: #F25928;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 5px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  font-size: 16px;
  margin-top: -2px;
  height: 22px;
  font-weight: 400;
  padding-right: 5px;
}

.PopularTours {
  padding: 0px 0px 65px;
  background-color: #fff;
}