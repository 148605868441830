/* loadingSkeleton.css */

body, html {
  height: 100%;
  margin: 0;
}

.loading-skeleton {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* You can customize the background color and opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Adjust the z-index to make sure it's on top of other elements */
}

.square {
  width: 100%; /* Adjust the size of the square */
  height: 100%; /* Adjust the size of the square */
  background: linear-gradient(90deg, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: bgPos 1.5s linear infinite;
}

@keyframes bgPos {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

/* Hide loading skeleton when data is loaded */
.hidden {
  display: none;
}
